// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lime from "../../../../files/icons/rency/square_lime.svg";
import square_black from "../../../../files/icons/rency/square_black.svg";
import sw_competence from "../../../../files/images/pages/embedded/sw_competence.webp";



/**
 * EmbedddedSw section
 * informs readers about our competences and knowledge base in Embedded SW development
 * @returns HTML code with EmbeddedSw section for EMBEDDED page
 */
export default function EmbeddedSw() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="bg-white p-1">
                <Container>
                    <Row className="scroll-anim-1250 mt-3 mt-lg-5 blog-older-container-ms project-tile-border project-tile-box-shadow-divelit-grey-20">
                        <Col lg={6} xl={7} className="order-2 order-lg-2 ps-0 pe-0 blog-older-container-me-text-border">
                            <div className="mt-3 me-3 ms-3 mb-2 mt-lg-5 me-lg-5 ms-lg-5 mb-lg-5">
                                <Row className="d-flex flex-row align-items-center">
                                    <img src={square_lime} className="home-experts-img w-fit-content mb-2" alt={translation.alt.square_lime}/>
                                    <h4 className="w-fit-content">{translation.sw_competence.header}</h4>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} className="mt-4 mt-lg-4 ms-2 ms-md-3 ms-xl-5 pe-0 ps-0" >
                                        <ul className="paragraph-small w-fit-content">
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.sw_competence.list.part1}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.sw_competence.list.part2}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.sw_competence.list.part3}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.sw_competence.list.part4}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.sw_competence.list.part5}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.sw_competence.list.part6}</li>
                                            <li className="ps-md-2 pt-1 pt-lg-2 pb-1 pb-lg-2 mb-0">{translation.sw_competence.list.part7}</li>
                                        </ul>  
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg={6} xl={5} className="order-1 order-lg-1 pe-0 ps-0 bg-white text-center blog-older-container-me-img-border">
                            <div className="embedded-img-thumbnail blog-older-container-me-img-border">
                                <div className="d-flex flex-column" >
                                    <p className="paragraph-medium align-self-center mt-auto mb-auto bg-divelit-lemon"></p>
                                </div>
                                <img className="blog-latest-img blog-older-container-me-img-border pe-0" alt={translation.alt.embd_sw_competence} src={sw_competence}/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="p-4 p-sm-4 p-md-4 p-lg-5 scroll-anim-1500">
                        <Col xs={12} sm={12} md={12} lg={10} xl={8} className="p-4 p-lg-5 bg-divelit-lime project-tile-border embedded-sw-tile-position">
                            <Row className="">
                                <Col xs={12} sm={12} className="pe-0 ps-0 mx-md-auto">
                                    <div className="row d-flex flex-row align-items-center justify-content-md-center">
                                        <img className="project-square-img w-fit-content" src={square_black}  alt={translation.alt.square_black}/>
                                        <h5 className="header-4-extra-small mb-0 font-black w-fit-content">{translation.sw_knowledge.header}</h5>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} sm={12} md={3} className="mt-4 mt-lg-5 pe-0 ps-0">
                                    <ul className="paragraph-small mx-md-auto w-fit-content">
                                        <li>{translation.sw_knowledge.list.part1}</li>
                                        <li>{translation.sw_knowledge.list.part2}</li>
                                        <li>{translation.sw_knowledge.list.part3}</li>
                                        <li>{translation.sw_knowledge.list.part4}</li>
                                    </ul>  
                                </Col>
                                <Col md={1}></Col>
                                <Col xs={12} sm={12} md={3} className="mt-3 mt-lg-5 pe-0 ps-0">
                                    <ul className="paragraph-small mx-md-auto w-fit-content">
                                        <li>{translation.sw_knowledge.list.part5}</li>
                                        <li>{translation.sw_knowledge.list.part6}</li>
                                        <li>{translation.sw_knowledge.list.part7}</li>
                                        <li>{translation.sw_knowledge.list.part8}</li>
                                    </ul>    
                                </Col>
                                <Col md={1}></Col>
                                <Col xs={12} sm={12} md={3} className="mt-3 mt-lg-5 pe-0 ps-0">
                                    <ul className="paragraph-small mx-md-auto w-fit-content">
                                        <li>{translation.sw_knowledge.list.part9}</li>
                                        <li>{translation.sw_knowledge.list.part10}</li>
                                        <li>{translation.sw_knowledge.list.part11}</li>
                                        <li>{translation.sw_knowledge.list.part12}</li>
                                    </ul>    
                                </Col>
                            </Row> 
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
