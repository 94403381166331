// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";
import BtnBgLemonOpenPositions from "../../../../components/btn/btn-bg-lemon-open-positions";

/**
 * CareerJoinUs section
 * some pick-up text with button and bg-img
 * @returns HTML code for CAREER page section CareerJoinUs 
 */
export default function CareerJoinUs() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="career-join-us-section navbar-size-compensation">
                <div className="pt-3 pt-lg-5"></div>
                <div className="pt-3 pt-lg-5 pb-3 pb-lg-5 career-join-us-bg-img">
                    <Container className="">
                        <Row className="">
                            <Col xs={12} sm={12} md={8} lg={6} xl={5} className="mb-5">
                                <h1 className="font-white fade-in-750-down-to-top">{translation.header.part1} <span className="font-divelit-lemon">{translation.header.part2}</span></h1>
                                <p className="mt-3 mt-md-5 paragraph-medium font-white fade-in-1000-down-to-top ">{translation.paragraph}</p>
                                {/*<a href={"#positions"} className="mt-2 mt-md-5 button paragraph-small scroll-anim-1250">{translation.btn_positions}<img className="button-arrow"></img></a>*/}
                                <div className="mt-2 mt-md-5 fade-in-1250-down-to-top">
                                    <BtnBgLemonOpenPositions target="career#positions"></BtnBgLemonOpenPositions>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}
