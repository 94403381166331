/**
 * this variable represents GLOBAL LOADING status: 
 * 1) in a situation when data from IS are being loaded it is set to TRUE
 * 2) when nothing is being loaded value is FALSE
 */
export let globalLoading = {
    value: false,
    switch: function() {
        if(this.value === false) {
            this.value = true;
        }
        else {
            this.value = false;
        }
    }
}
