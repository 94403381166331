// utils
import React from "react";
import { useMatch } from "react-router-dom";

// custom utils
import { getCurrentLanguage, getUrlPrefix, LANGUAGES } from "../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

/**
 * BtnBgLemonLearnMore component
 * contains BtnBgLemonLearnMore component 
 * @returns HTML element BtnBgLemonLearnMore component
 */
export default function BtnBgLemonLearnMore(props: {target:string})  {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            {/*<a href={getUrlPrefix(language) + props.target} className="button-w-border paragraph-small">{translation.btn.border.uwb}<img className="button-arrow"></img></a>*/}
            {/*<a href={getUrlPrefix(language) + props.target} className="button paragraph-small">{translation.btn.learn_more}<img className="button-arrow"></img></a>*/}
            <a href={getUrlPrefix(language) + props.target} className="button-wo-border bg-divelit-lemon paragraph-small">{translation.btn.learn_more}<img className="button-arrow"></img></a>                                     
        </>
    );
}
