// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../../../utils/langUtils";
import { select } from "./components/work-position-select/work-position-select";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

//files

// components
import BtnBgLemonWorkPosition from "../../../../../../components/btn/btn-bg-lemon-work-position";

/**
 * WorkPosition
 * @param props 1) work_position (text content) 
 * @param props 2) id (to distinguish callapsibles - so click on position will open right position details)
 * @returns one WorkPosition with corresponding content in selected language for CAREER page
 */
export default function WorkPosition(props: {work_position: any, id: any}) {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className={"scroll-anim-1000" + " " + props.id} >
                <a className="row pt-4 pb-4 career-work-position-button mx-auto collapsed font-black" data-bs-toggle="collapse" href={"#" + props.id} role="button" aria-expanded="false" aria-controls="collapseExample" style={{textDecoration: "none"}}>
                    <Col xs={2} sm={2} md={1} className="my-auto text-center">
                        <img src={process.env.PUBLIC_URL + '/icons/' + props.work_position.icon} className="work-position-icon" alt={props.work_position.icon}/>
                    </Col>
                    <Col xs={10} sm={10} md={11} className="">
                        <Row className="">
                            <Col xs={12} sm={12} className="">
                                <h4 className="">
                                    {props.work_position.name}
                                </h4>
                            </Col>
                        </Row>
                        <Row className="">
                            <Col xs={12} sm={12} className="">
                                <p className="paragraph-small mb-0">{props.work_position.time} / {props.work_position.programming_languages}</p>
                            </Col>
                            {/*<div className="col-8" style={{textAlign: "right"}}>
                                <p className="paragraph-small" style={{marginBottom: "0"}}></p>
                            </div>*/}
                        </Row>
                    </Col>
                </a>
                <Row className="collapse pt-3 pb-2 m-0" id={props.id}>
                    <Col xs={12} sm={12} md={1} className="my-auto text-end">

                    </Col>
                    <Col xs={12} sm={12} md={11} className="">
                        <p className="paragraph-small">
                            {props.work_position.description}
                        </p>
                        {props.work_position.content.map((item:any, i:any) => {
                                return (
                                    <div key={i}>
                                        <h5 className="">{item.header}</h5>
                                        <ul className="paragraph-small">
                                            {item.rows.map((data:any, j:any) => {
                                                return (
                                                    <li style={{textAlign: "left"}} key={j}>
                                                        {data}
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                )
                        })}
                        {/*<div className="btn-know-more-hover" onClick={() => setPreselectPosition2(props.work_position.name)}>*/}
                        <div className="mt-2 mt-md-3 mb-md-4">
                            {/*<BtnBgLemonLearnMore target={"career" + "#form_section"}></BtnBgLemonLearnMore>*/}
                        </div>
                        {/*<a href={"#form_section"} className="button paragraph-medium btn-know-more-listener mt-2 mt-md-3 mb-md-4 " onClick={() => select.switch(props.work_position.name)}>{translation.btn_know_more}<img className="button-arrow"></img></a>*/}
                        <BtnBgLemonWorkPosition onClick={() => select.switch(props.work_position.name)}></BtnBgLemonWorkPosition>
                    </Col>
                </Row>
            </div>
            <hr className="sepparator-horiz-divelit-grey-20 sepparator-w-100 scroll-anim-1250"/>
        </>
    );
}
