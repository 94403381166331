// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_black from "../../../../files/icons/rency/square_black.svg";

/**
 * HomeCollaboration section
 * presents users with 2 options how we can cooperate with our partners - project & team leasing
 * @returns HTML code with HomeCollaboration section used on Home Page and some other pages as well
 */
export default function HomeCollaboration() {
    
    // This variant is required to enable button to redirect to required language version
    /*const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let language = getCurrentLanguage(lang);*/

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="pt-5 pb-5">
                <Container className="scroll-anim-750">
                    <Row className="text-center">
                        <Col xs={12} sm={12} className="mx-auto">
                            <div className="">
                                <h3 className="mt-3 mt-lg-5 mb-3 mb-lg-5 pb-3 pb-lg-5 font-divelit-grey-50">{translation.header}</h3>
                            </div>
                        </Col>
                    </Row>  
                </Container>
                <Container className="">
                    <Row className="mb-md-3 mb-lg-3 pb-5 pb-md-5 pb-lg-5 ">
                        <Col xs={12} sm={12} md={6} className="scroll-anim-1000">
                            <div className="me-lg-4 p-3 p-md-3 p-lg-5 font-black bg-divelit-lemon project-tile-border h-100">
                                <Row className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img-1 w-fit-content" alt={translation.alt.square_black}/>
                                    <h5 className="mb-0 w-fit-content">{translation.project.header}</h5>
                                </Row>
                                <Row className="">
                                    <Col xs={12} sm={12} className="">
                                        <ul className="mt-4">
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part1}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part2}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part3}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part4}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part5}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part6}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part7}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-3 ps-md-2 paragraph-small">{translation.project.list.part8}</p>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={6} className="scroll-anim-1250">
                            <div className="mt-3 mt-md-0 ms-lg-4 p-3 p-md-3 p-lg-5 font-black bg-divelit-lime project-tile-border h-100">
                                <Row className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img-1 w-fit-content" alt={translation.alt.square_black}/>
                                    <h5 className="mb-0 w-fit-content">{translation.team_leasing.header}</h5>
                                </Row>
                                <Row className="">
                                    <Col xs={12} sm={12} className="">
                                        <ul className="mt-4">
                                            <li>
                                                <p className="mb-0 pt-4 ps-md-2 paragraph-small">{translation.team_leasing.list.part1}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-4 ps-md-2 paragraph-small">{translation.team_leasing.list.part2}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-4 ps-md-2 paragraph-small">{translation.team_leasing.list.part3}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-4 ps-md-2 paragraph-small">{translation.team_leasing.list.part4}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-4 ps-md-2 paragraph-small">{translation.team_leasing.list.part5}</p>
                                            </li>
                                            <li>
                                                <p className="mb-0 pt-4 ps-md-2 paragraph-small">{translation.team_leasing.list.part6}</p>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>                
            </div>
        </>
    );
}
