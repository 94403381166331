// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import career_brno from "../../../../files/images/pages/career/brno_cathedral.webp";
import career_olomouc from "../../../../files/images/pages/career/olomouc_column.webp";

/**
 * CareeerImg section 
 * contains OLOMOUC & BRNO imgages
 * @returns CareerImg section for CAREER page
 */
export default function CareerImg() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="career-img-section">
                <Container className="career-img-container-position text-center">
                    <Row>
                        <Col xs={12} sm={12}>
                            <img src={career_brno} className="career-img-brno fade-in-750-down-to-top" alt={translation.alt.brno_img}/>
                            <h4 className="font-white career-img-header-brno text-start fade-in-750-down-to-top">
                                Brno<br className="career-header-br"/> Office
                            </h4>
                        </Col>
                        <Col xs={12} sm={12}>
                            <hr className="mx-auto career-img-hr-brno fade-in-1000-down-to-top"></hr>
                            <img src={career_olomouc} className="career-img-olomouc fade-in-750-down-to-top" alt={translation.alt.olomouc_img}/>
                            <h4 className="career-img-header-olomouc text-end fade-in-1250-down-to-top">
                                Olomouc<br className="career-header-br"/> Office
                            </h4>
                            <hr className="mx-auto career-img-hr-olomouc fade-in-1000-down-to-top"></hr>                            
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
