// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lemon from "../../../../files/icons/rency/square_lemon.svg";
import square_lime from "../../../../files/icons/rency/square_lime.svg";
import square_grass from "../../../../files/icons/rency/square_grass.svg";

// types
type schemaJson = typeof translation_cs;

/**
 * HomeExperts section
 * some basic info about our company - founded, employees, team image
 * @returns HTML code with HomeExperts section for HOME page
 */
export default function HomeExperts() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="bg-divelit-rency-grey-90">
                <span id="experts"></span>
                <div className="home-experts-bg-img">
                    <Container className="">
                        <Row className="home-experts-paragraph-position">
                            <Col xs={12} sm={12} md={6} lg={5} xl={5} xxl={4} className="order-2 order-md-1" >
                                <p className="paragraph-small p-3 p-sm-4 p-md-4 p-lg-5 project-tile-border bg-divelit-lemon scroll-anim-750">
                                    {translation.paragraph}
                                </p>
                            </Col>
                            <Col xs={12} sm={12} md={6} lg={6} xl={7} xxl={7} className="order-1 order-md-2 offset-lg-1 offset-xl-0 offset-xxl-1 " >
                                <Row className="ms-xl-5">
                                    <Col className="">
                                        <h2 className="font-white scroll-anim-1000">
                                            {translation.header.part1}
                                        </h2>
                                        <h2 className="font-divelit-lemon scroll-anim-1250">
                                            {translation.header.part2}
                                        </h2>
                                    </Col>
                                </Row>
                            </Col>
                        </Row> 
                        <Row className="home-experts-facts-position mt-md-3 mt-xl-5">
                            <div className="d-flex flex-row">
                                <div className="flex-grow-1 text-center scroll-anim-750">
                                    <div className="style-zoom-4">
                                        <h4 className="font-divelit-lemon">2017</h4>
                                        <p className="paragraph-small font-white">
                                            {translation.founded}<br></br>
                                            {/*<span className="d-none d-md-inline invisible">{translation.founded}</span>*/}
                                        </p>
                                        <img src={square_lemon} className="d-inline-block home-experts-img mt-3" alt={translation.alt.square_lemon}/>
                                    </div>
                                </div>
                                <div className="sepparator-vert sepparator-vert-grey-70 scroll-anim-1000"></div>
                                <div className="flex-grow-1 text-center scroll-anim-1000">
                                    <div className="style-zoom-4">
                                        <h4 className="font-divelit-lime w-fit-content mx-auto">25</h4>
                                        <p className="paragraph-small font-white">
                                            {translation.members}
                                        </p>
                                        <img src={square_lime} className="d-inline-block home-experts-img mt-3" alt={translation.alt.square_lime}/>
                                    </div>
                                </div>
                                <div className="sepparator-vert sepparator-vert-grey-70 scroll-anim-1250"></div>
                                <div className="flex-grow-1 text-center scroll-anim-1250">
                                    <div className="style-zoom-4">
                                        <h4 className="font-divelit-grass">1</h4>
                                        <p className="paragraph-small font-white">
                                            {translation.patents}
                                        </p>
                                        <img src={square_grass} className="d-inline-block home-experts-img mt-3" alt={translation.alt.square_grass}/>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}
