// utils
import React from "react";
import { useMatch } from "react-router-dom";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// components
import BtnBgLemonLearnMore from "../../../../components/btn/btn-bg-lemon-learn-more";

// files

/**
 * HomeHero section
 * composed of our slogan and our 3D logo
 * @returns HTML code with HomeHero section for HOME page (main HOME page img on top)
 */
export default function HomeHero() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="home-hero-section navbar-size-compensation">
                <div className="home-hero-bg-img">
                    <div className="home-hero-text d-flex flex-md-row">
                        <div className="home-hero-text-alignment flex-column d-flex">
                            <div className="home-hero-text-alignment-content">
                                <h2 className="font-white fade-in-750-down-to-top">{translation.header.part1}</h2>
                                <h2 className="font-divelit-lemon fade-in-1000-down-to-top">{translation.header.part2}</h2>
                                <p className="paragraph-medium font-white mt-3 mt-md-5 fade-in-1250-down-to-top">
                                    {translation.paragraph.part1}<br/>{translation.paragraph.part2}<br/>{translation.paragraph.part3}
                                </p>
                                {/*<a href={"#experts"} className="button paragraph-small mt-3 mt-md-5 scroll-anim-1500">{translation.btn_find_out}<img className="button-arrow"></img></a>*/}
                                <div className="mt-3 mt-md-5 fade-in-1250-down-to-top">
                                    <BtnBgLemonLearnMore target="#experts"></BtnBgLemonLearnMore>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className="sepparator-horiz-divelit-grey-30"></hr>
            </div>
        </>
    );
}
