// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// components
import OurWorkDivelitIs from "../../../our-work/components/our-work-divelit-is/our-work-divelit-is";
import OurWorkLinetServiceApp from "../../../our-work/components/our-work-linet-service-app/our-work-linet-service-app";
import OurWorkNumazon from "../../../our-work/components/our-work-numazon/our-work-numazon";

// files

/**
 * SoftwareProjects section
 * contains selected embedded projects
 * @returns HTML code with SoftwareProjects section for SOFTWARE page 
 */
export default function SoftwareProjects() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="bg-white pt-4 pb-4">
                <Container className="mt-5">
                    <Row className="">
                        <Col xs={12} sm={12} className="text-center scroll-anim-750">
                            <h3 className="font-divelit-grey-50">{translation.header.part1} {translation.header.part2}</h3>
                        </Col>
                    </Row>
                </Container>
                <OurWorkDivelitIs btn={false}></OurWorkDivelitIs>
                <OurWorkLinetServiceApp btn={false}></OurWorkLinetServiceApp>
                <OurWorkNumazon btn={false}></OurWorkNumazon>
            </div>
        </>
    );
}
