// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";


// files
import square_lemon from "../../../../files/icons/rency/square_lemon.svg";

import laser_game from "../../../../files/images/pages/career/IMG_2708-export.webp";
import brainstorming from "../../../../files/images/pages/career/IMG_2730-export.webp";
import divelit_gingerbread from "../../../../files/images/pages/career/IMG_2749-export.webp";
import divelit_logo_kebab from "../../../../files/images/pages/career/IMG_2767-export.webp";
import divelit_logo from "../../../../files/images/pages/career/IMG_2772-export.webp";
//import champagne_slowmo from './champagne_slowmo.mp4';


// types
type schemaJson = typeof translation_cs;

/**
 * CareerTeam section
 * Divelit photo section for CAREER page 
 * @returns CareerTeam section for CAREER page
 */
export default function CareerTeam() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>        
            <div className="pt-3 pt-lg-5 pb-3 pb-lg-5 bg-divelit-grey-90">
                <Container className="career-team-container">
                    <Row className="">
                        <Col xs={12} sm={12} className="">
                            <div className="d-flex flex-row justify-content-center mt-2 mt-md-3 mt-lg-5 mb-2 mb-lg-4 mb-md-3 scroll-anim-750">
                                <img src={square_lemon} className="home-experts-img me-3 me-lg-5" alt={translation.alt.square_lemon}/> 
                                <h3 className="font-white mt-auto mb-auto">{translation.header.part1} <span className="font-divelit-lemon">{translation.header.part2}</span></h3>
                            </div>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col xs={12} sm={12}>
                            <h4 className="mt-4 mt-md-0 mb-2 font-white text-start scroll-anim-1250 text-end career-team-laser-game-header w-fit-content">
                                Teambuildings<br className="career-header-br"/> 
                            </h4>
                            <hr className="sepparator-horiz-white-2px sepparator-w-20 d-none d-md-block career-team-laser-game-hr scroll-anim-1000"></hr>
                            <img src={laser_game} className="mt-2 mt-md-3 mt-lg-5 career-team-laser-game-img project-tile-border" alt={translation.alt.laser_game}/>                                         
                        </Col>
                    </Row>
                    <Row className="mt-4 mt-md-4 mt-lg-5 pt-0 pt-lg-3 pb-3 pb-lg-5 career-team-gingerbread-md-mt">
                        <Col xs={12} sm={12} className="text-end">
                            <h4 className="mb-2 font-white text-start scroll-anim-1250 text-end career-team-gingerbread-header w-fit-content">
                                Delicious<br className="career-header-br"/> Gingerbreads
                            </h4>
                            <hr className="sepparator-horiz-white-2px sepparator-w-20 d-none d-md-block career-team-gingerbread-hr scroll-anim-1000"></hr>
                            <img src={divelit_gingerbread} className="mt-md-0 me-0 mb-3 mb-md-4 ms-auto career-team-gingerbread-img project-tile-border" alt={translation.alt.divelit_gingerbread} style={{zIndex: "1", position: "relative"}}/>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col xs={12} sm={12}>
                        <video width="50%" height="auto" autoPlay muted loop src={champagne_slowmo}>
                        </video>
                        </Col>
                    </Row>*/}
                    <Row className="mt-3 mt-md-5 career-team-logo-section">
                        <Col xs={12} sm={12} className="career-img-section" style={{background: "var(--divelit-grey-90)"}}>
                            <Container className="container career-img-container-position text-center">
                                <Row>
                                    <Col xs={12} sm={12}>
                                        <img src={divelit_logo} className="career-img-brno" alt={translation.alt.divelit_logo}/>
                                        <h4 className="font-white career-img-header-brno text-start scroll-anim-1250">
                                            Driven by<br className="career-header-br"/> <span className="font-divelit-lemon">Innovations</span>
                                        </h4>
                                        <hr className="mx-auto career-img-hr-brno scroll-anim-1000" style={{zIndex:"1"}}></hr>
                                    </Col>
                                    <Col xs={12} sm={12}>
                                        {/*<img src={divelit_logo_kebab} className="career-img-olomouc scroll-anim-750" alt={translation.alt.divelit_logo_kebab} style={{filter: "grayscale(0.0)"}}/>*/}
                                        <img src={divelit_logo_kebab} className="career-img-olomouc" alt={translation.alt.divelit_logo_kebab}/>
                                        <h4 className="career-img-header-olomouc text-end scroll-anim-1250 font-white mt-1 mt-sm-0">
                                            Delivered by<br className="career-header-br"/> <span className="font-divelit-lemon">People</span>
                                        </h4>
                                        <hr className="mx-auto career-img-hr-olomouc scroll-anim-1000 sepparator-horiz-white-2px"></hr>
                                    </Col>
                                </Row>
                            </Container>
                        </Col>
                    </Row>
                    <Row className="mt-0 mt-md-4 mt-lg-5 pt-0 pt-md-3 career-team-logo-section">
                        <Col xs={12} sm={12} className="mt-3 mt-md-5">
                            <img src={brainstorming} className="mt-5 mt-md-0 mb-2 mb-md-4 career-team-brainstorming-img project-tile-border" alt={translation.alt.brainstorming}/>
                            <h4 className="font-white text-start scroll-anim-1250 career-team-brainstorming-header w-fit-content" >
                                Team<br className="career-header-br"/> Brainstormings
                            </h4>
                            <hr className="sepparator-horiz-white-2px sepparator-w-20 d-none d-md-block career-team-brainstorming-hr scroll-anim-1000"></hr>                                      
                        </Col>
                    </Row>
                    {/*<Row className="mt-4 mt-md-4 mt-lg-5 pt-0 pt-lg-3 pb-3 pb-lg-5 career-team-gingerbread-md-mt">
                        <Col xs={12} sm={12} className="text-end">
                            <h4 className="mb-2 font-white text-start scroll-anim-1250 text-end career-team-gingerbread-header w-fit-content">
                                Delicious<br className="career-header-br"/> gingerbreads
                            </h4>
                            <hr className="sepparator-horiz-white-2px sepparator-w-20 d-none d-md-block career-team-gingerbread-hr scroll-anim-1000"></hr>
                            <img src={divelit_gingerbread} className="mt-md-0 me-0 mb-3 mb-md-4 ms-auto career-team-gingerbread-img project-tile-border" alt={translation.alt.divelit_gingerbread}/>
                        </Col>
                </Row>*/}
                </Container>
            </div>
            
            {/* much easier to maintan version - photos as "randomly" placed tiles */}
            {/*<div className="pt-3 pt-lg-5 pb-3 pb-lg-5 bg-divelit-grey-90">
                <Container className="">
                    <Row className="">
                        <Col xs={12} sm={12} className="">
                            <div className="d-flex flex-row justify-content-center mt-2 mt-md-3 mt-lg-5 mb-2 mb-lg-4 mb-md-3">
                                <img src={square_lemon} className="home-experts-img me-5" alt={translation.alt.square_lemon}/> 
                                <h3 className="font-white mt-auto mb-auto">{translation.header.part1} <span className="font-divelit-lemon">{translation.header.part2}</span></h3>
                            </div>

                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col xs={12} sm={12} md={7}>
                            <img src={laser_game} className="mt-2 mt-md-3 mt-lg-5 w-100 h-auto project-tile-border" alt={translation.alt.laser_game}/>                                         
                            <Row>
                                <Col xs={12} sm={12} md={4}>
                                </Col>
                                <Col xs={12} sm={12} md={8}>
                                    <img src={divelit_logo} className="mt-3 mt-md-4 mb-2 mb-md-4 w-100 h-auto project-tile-border" alt={translation.alt.divelit_logo}/>

                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} md={5}>
                            <img src={divelit_gingerbread} className="mt-3 mt-md-0 me-0 mb-3 mb-md-4 ms-auto w-100 h-auto project-tile-border" alt={translation.alt.divelit_gingerbread}/>
                            <Row>
                                <Col xs={12} sm={12} md={11}>
                                    <img src={divelit_logo_kebab} className="mt-2 mt-md-3 mt-lg-2 w-100 h-auto scroll-anim-750 project-tile-border" alt={translation.alt.divelit_logo_kebab}/>
                                </Col>
                                <Col xs={12} sm={12} md={1}>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="">
                        <Col xs={12} sm={12} md={6}>
                            <img src={brainstorming} className="mt-2 mt-md-3 mt-lg-2 w-100 h-auto scroll-anim-750 project-tile-border" alt={translation.alt.brainstorming}/>
                        </Col>
                        <Col xs={12} sm={12} md={6}>
                            <img src={brainstorming} className="mt-2 mt-md-3 mt-lg-2 w-75 h-75 scroll-anim-750 project-tile-border" alt={translation.alt.brainstorming}/>
                        </Col>
                    </Row>
                </Container>

            </div>*/}
        </>
    );
}
