// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import radim_stupka_g_05 from "../../../../files/images/pages/contact/radim-stupka-circle-g-05.webp";
import matej_ocenasek_g_05 from "../../../../files/images/pages/contact/matej-ocenasek-circle-g-05.webp";
//import matej_kadlicek_g_05 from "../../../../files/images/pages/contact/matej-kadlicek-circle-g-05.webp";
import martin_mikulka_g_05 from "../../../../files/images/pages/contact/martin-mikulka-circle-g-05.webp";
import jiri_jurecek_g_05 from "../../../../files/images/pages/contact/jiri-jurecek-circle-g-05.webp";

/**
 * ContactTeam section
 * contains selected profile photos with contact information
 * @returns HTML code for ContactTeam section used on CONTACT page
 */
export default function ContactTeam() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // Base64 encoded mail address UTF-8, LF (Unix)
    var radimStupkaMail = "c3R1cGthLnJhZGltQGRpdmVsaXQuY3o=";
    const radimStupka = document.getElementById("radim-stupka");
    if(radimStupka) {
        radimStupka.innerHTML = atob(radimStupkaMail);
        radimStupka.setAttribute("href", "mailto:".concat(atob(radimStupkaMail)));
        radimStupka.setAttribute("data-hover", atob(radimStupkaMail));
    }

    // Base64 encoded mail address UTF-8, LF (Unix)
    var matejOcenasekMail = "b2NlbmFzZWtAZGl2ZWxpdC5jeg==";
    const matejOcenasek = document.getElementById("matej-ocenasek");
    if(matejOcenasek) {
        matejOcenasek.innerHTML = atob(matejOcenasekMail);
        matejOcenasek.setAttribute("href", "mailto:".concat(atob(matejOcenasekMail)));
        matejOcenasek.setAttribute("data-hover", atob(matejOcenasekMail));
    }
    
    // Base64 encoded mail address UTF-8, LF (Unix)
    var martinMikulkaMail = "bWlrdWxrYUBkaXZlbGl0LmN6";
    const martinMikulka = document.getElementById("martin-mikulka");
    if(martinMikulka) {
        martinMikulka.innerHTML = atob(martinMikulkaMail);
        martinMikulka.setAttribute("href", "mailto:".concat(atob(martinMikulkaMail)));
        martinMikulka.setAttribute("data-hover", atob(martinMikulkaMail));
    }

    // Base64 encoded mail address UTF-8, LF (Unix)
    var jiriJurecekMail = "anVyZWNla0BkaXZlbGl0LmN6";
    const jiriJurecek = document.getElementById("jiri-jurecek");
    if(jiriJurecek) {
        jiriJurecek.innerHTML = atob(jiriJurecekMail);
        jiriJurecek.setAttribute("href", "mailto:".concat(atob(jiriJurecekMail)));
        jiriJurecek.setAttribute("data-hover", atob(jiriJurecekMail));
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="bg-white">
                <Container className="pt-3 pt-md-5 pb-4 pb-md-5">
                    <Row className="mt-3 mt-lg-5">
                        <Col xs={12} sm={12} className="scroll-anim-1000">
                            <h3 className="text-center font-divelit-grey-50">{translation.header}</h3>
                        </Col>
                    </Row>
                    <Row className="mt-3 mt-lg-4">
                        <Col>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="p-5 p-md-3 p-lg-5 text-center  scroll-anim-1250">
                            <img src={radim_stupka_g_05} style={{height: "auto", width: "100%"}} className="p-sm-5 p-md-0 p-xl-3"></img>
                            <h5 className="mt-3 mt-md-5">{translation.team.radim_stupka.name}</h5>
                            <p className="paragraph-medium">{translation.team.radim_stupka.role}</p>
                            <a id="radim-stupka" className="contact-team-link-mail paragraph-small font-divelit-grey-50" href="" data-hover=""></a>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="p-5 p-md-3 p-lg-5 text-center scroll-anim-1500">
                            <img src={matej_ocenasek_g_05} style={{height: "auto", width: "100%"}} className="p-sm-5 p-md-0 p-xl-3"></img>
                            <h5 className="mt-3 mt-md-5">{translation.team.matej_ocensasek.name}</h5>
                            <p className="paragraph-medium">{translation.team.matej_ocensasek.role}</p>
                            <a id="matej-ocenasek" className="contact-team-link-mail paragraph-small font-divelit-grey-50" href="" data-hover=""></a>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                    <Row className="mt-3 mt-lg-4">
                        <Col>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="p-5 p-md-3 p-lg-5 text-center scroll-anim-2000">
                            <img src={martin_mikulka_g_05} style={{height: "auto", width: "100%"}} className="p-sm-5 p-md-0 p-xl-3"></img>
                            <h5 className="mt-3 mt-md-5">{translation.team.martin_mikulka.name}</h5>
                            <p className="paragraph-medium">{translation.team.martin_mikulka.role}</p>
                            <a id="martin-mikulka" className="contact-team-link-mail paragraph-small font-divelit-grey-50" href="" data-hover=""></a>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="p-5 p-md-3 p-lg-5 text-center scroll-anim-2250">
                            <img src={jiri_jurecek_g_05} style={{height: "auto", width: "100%"}} className="p-sm-5 p-md-0 p-xl-3"></img>
                            <h5 className="mt-3 mt-md-5">{translation.team.jiri_jurecek.name}</h5>
                            <p className="paragraph-medium">{translation.team.jiri_jurecek.role}</p>
                            <a id="jiri-jurecek" className="contact-team-link-mail paragraph-small font-divelit-grey-50" href="" data-hover=""></a>
                        </Col>
                        <Col>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
