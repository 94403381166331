// utils
import React from "react";
import { useMatch, Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";


// custom utils
import { getCurrentLanguage, getUrlPrefix, LANGUAGES } from "../../../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_black from "../../../../../../files/icons/rency/square_black.svg";
import services_sw from "../../../../../../files/images/pages/software/divelit_code_screen.webp";

/**
 * ServicesSoftware section
 * composed from representative photo of some SW product with some text information
 * @returns HTML code with ServicesSoftware section for HomeServices section used on HOME page
 */
export default function ServicesSoftware() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    return (
        <>
            <Container>
                <Row className="mt-3 mt-lg-5 scroll-anim-1000 blog-older-container blog-older-container-ms" style={{height:"fit-content"}}>
                    <Col xs={12} sm={12} className="pe-0 ps-0 order-1 order-lg-2 bg-divelit-grey-10 text-center blog-older-container-me-img-border" style={{height: "auto"}}>
                        <Link className="font-black text-start" to={getUrlPrefix(language) + "software"} style={{textDecoration: "none"}}>
                            <div className="blog-older-img-thumbnail-test project-tile-border">
                                <div className="d-flex flex-column thumbnail">
                                    <p className="mt-auto mb-auto paragraph-medium blog-latest-img-btn align-self-center bg-divelit-lime">{translation.btn_read_more}<img className="button-arrow"></img></p>
                                </div>
                                <div className="content">
                                    {/*<img className="pe-0 blog-latest-img" alt={translation.alt.services_sw} src={services_hw}/>*/}
                                    <Row className="m-0">
                                        <Col xs={12} sm={12} lg={8} xl={8} xxl={8} className="ps-0 pe-0 order-2 order-lg-2 blog-older-container-me-text-border bg-divelit-lime" style={{zIndex: "2"}}>
                                            

                                            <div className="mt-3 mt-lg-5 me-3 me-lg-5 mb-2 mb-lg-5 ms-3 ms-lg-5">
                                                <Row className="d-flex flex-row align-items-center">
                                                    <img src={square_black} className="home-experts-img w-fit-content" alt={translation.alt.square_black}/>
                                                    <h4 className="mb-0 w-fit-content" >02</h4>
                                                </Row>
                                                <Row>
                                                    <Col xs={12} sm={12}>
                                                        <div className="w-fit-content">
                                                            <h5 className="mt-3 mt-lg-5 mb-0">
                                                            {translation.header}
                                                            </h5>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="">
                                                    <Col xs={12} sm={12} className="">
                                                        <p className="mt-2 mt-lg-3 paragraph-small">
                                                        {translation.paragraph}
                                                        </p>
                                                    </Col>
                                                </Row>
                                                {/*<Row className="">
                                                    <Col xs={12} sm={12} className="pt-2 pt-lg-5">
                                                        <a href={getUrlPrefix(language) + "embedded"} className="mt-auto mb-0 button-w-border bg-divelit-lemon paragraph-small scroll-anim-1000">{translation.btn_read_more}<img className="button-arrow"></img></a>                                     
                                                    </Col>
                                                </Row>*/}
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} lg={4} xl={4} xxl={4} className="pe-0 ps-0 order-1 order-lg-1 bg-divelit-grey-10 blog-older-container-me-img-border h-auto">

                                            <div className="d-flex flex-row align-items-center justify-content-center h-100">
                                                <img className="pe-0 home-services-img home-services-sw-img-pos" alt={translation.alt.services_sw} src={services_sw}/>

                                            </div>
                                            <div className="home-services-img-lime-arrow">

                                            </div>
                                        </Col>                                        
                                    </Row>
                                </div>
                            </div>
                        </Link>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
