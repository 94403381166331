// utils
import React from "react";
//import { useMatch } from "react-router-dom";

// custom utils
//import { getCurrentLanguage, LANGUAGES } from "../../utils/langUtils";

// translations
//import translation_cs from "../contact/lang/cs.json";
//import translation_en from "../contact/lang/en.json";
//import translation_de from "../contact/lang/de.json";

// files

// page sections
import EmbeddedHero from "./components/embedded-hero/embedded-hero";
import EmbeddedHw from  "./components/embedded-hw/embedded-hw";
import EmbeddedSw from  "./components/embedded-sw/embedded-sw";
import EmbeddedPartners from  "./components/embedded-partners/embedded-partners";
import EmbeddedProjects from  "./components/embedded-projects/embedded-projects";
import HomeCollaboration from "../home/components/home-collaboration/home-collaboration";
//import EmbeddedCollaboration from  "./components/embedded-collaboration/embedded-collaboration";
import EmbeddedContact from  "./components/embedded-contact/embedded-contact";


/**
 * CONTACT page
 * Handles whole CONTACT page with form - language, required input check, POST to the server as multipart/form-data
 * @returns HTML element for CONTACT page with <form>
 */
export default function Index() {

    /*const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }*/

    // solves broken layout of some pages in build
    //const [hasMounted, setHasMounted] = React.useState(false);
    //React.useEffect(() => {
    //  setHasMounted(true);
    //}, []);
    //if (!hasMounted) {
    //  return null;
    //}
    
    return (
        <>
            <div className="fade-in-750">
                <EmbeddedHero></EmbeddedHero>
                <EmbeddedPartners></EmbeddedPartners>
                {/*<HomePartners></HomePartners>*/}
                <EmbeddedHw></EmbeddedHw>
                <EmbeddedSw></EmbeddedSw>
                <EmbeddedProjects></EmbeddedProjects>
                <HomeCollaboration></HomeCollaboration>
                {/*<EmbeddedCollaboration></EmbeddedCollaboration>*/}
                <EmbeddedContact></EmbeddedContact>            
            </div>
        </>
    );
}
