// utils
import React from "react";
import { useMatch } from "react-router-dom";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// components
import SoftwareHero from "./components/software-hero/software-hero";
import SoftwarePartners from "./components/software-partners/software-partners";
import HomeCollaboration from "../home/components/home-collaboration/home-collaboration";
//import EmbeddedCollaboration from "../embedded/components/embedded-collaboration/embedded-collaboration";
import EmbeddedContact from "../embedded/components/embedded-contact/embedded-contact";
import SoftwareProjects from "./components/software-projects/software-projects";

// files

/**
 * SOFTWARE page
 * readers are presented with our knowledge about SW DEVELOPMENT together with some of our most interesting projects
 * @returns HTML element for SOFTWARE
 */
export default function Index() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // solves broken layout of some pages in build
    //const [hasMounted, setHasMounted] = React.useState(false);
    //React.useEffect(() => {
    //  setHasMounted(true);
    //}, []);
    //if (!hasMounted) {
    //  return null;
    //}
    
    return (
        <>
            <div className="fade-in-750">
                <SoftwareHero></SoftwareHero>
                <SoftwarePartners></SoftwarePartners>
                <SoftwareProjects></SoftwareProjects>
                <HomeCollaboration/>
                {/*<EmbeddedCollaboration></EmbeddedCollaboration>*/}
                <EmbeddedContact></EmbeddedContact>                
            </div>
        </>
    );
}
