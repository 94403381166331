export enum LANGUAGES {
    English = "en",
    Czech = "cs",
    German = "de",
}

const DEFAULT = LANGUAGES.Czech;

/**
 * From obtained URL part gets information about which languge it is and returns that information 
 * so the file that called this function will be shown in that LANG option.
 * @param lang URL part that contains LANG info
 * @returns actual language
 */
export function getCurrentLanguage(lang: string | undefined) : LANGUAGES  {
    let out;
    switch (lang) {
        case LANGUAGES.Czech:
            out = LANGUAGES.Czech;
            break;
        case LANGUAGES.English:
            out = LANGUAGES.English;
            break;
        // TODO
        // case LANGUAGES.German:
        //     out = LANGUAGES.German;
        //     break;
        default:
            out = DEFAULT;
            break;
    }

    return out;
}

/**
 * returns "/" or "/lang/"
 * @param lang selected language
 * @returns part of URL with selected language option
 */
export function getUrlPrefix(lang: LANGUAGES) : string {
    if (lang === DEFAULT)
    {
        return "/";
    }
    else
    {
        return "/" + lang + "/";
    }
}

/**
 * When user changes language this function will takes actual URL and updates it so it contains information about newly selected (actual) language.
 * @param oldLang previously selected language
 * @param newLang laguage that will be used from now on
 * @param oldUrl previous URL with previous LANG
 * @returns new URL with new LANG 
 */
export function getNewUrlChangeLang(oldLang: LANGUAGES, newLang: LANGUAGES, oldUrl: string) : string {
    let url;
    if(oldLang !== DEFAULT)
    {//language was in url -> removing it
        let [nothing, lang, ...rest] = oldUrl.split('/')
        url = rest.join("/")
    }
    else
    {//language wasn't in url
        let [nothing, ...rest] = oldUrl.split('/')
        url = rest.join("/")
    }
    if (newLang === DEFAULT)
    {
        return "/" + url;
    }
    else
    {
        return "/" + newLang + "/" + url
    }
}
