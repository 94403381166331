// utils
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * When TO THE TOP button in the footer is CLICKED it scrolls all the way to the TOP of the PAGE
 * @returns PAGE scrolled to the TOP
 */
export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
