// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_black from  "../../../../files/icons/rency/square_black.svg";


/**
 * CareerRecruitment section
 * some pick-up text with button and bg-img
 * @returns HTML code for CAREER page section CareerRecruitment
 */
export default function CareerRecruitment() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <Container className="mt-5" style={{marginBottom: "10rem"}}>
                <Row>
                    <Col xs={12} sm={12} className="scroll-anim-750">
                        <h2 className="font-divelit-grey-50 text-center">{translation.header.part1}<span className="font-black"> {translation.header.part2}</span></h2>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={12} className="scroll-anim-1000">
                        <p className="paragraph-small mt-3 mt-lg-5 text-center">
                            {translation.paragraph}
                        </p>
                    </Col>
                </Row>
                <Row className="mt-3 mt-lg-5">
                    <Col xs={12} sm={12} lg={4}>
                        <div className="p-0 p-xl-2 p-xxl-3">
                            <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1250 career-recruitment-card-height" style={{borderTop: "12px solid var(--divelit-lemon)"}}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                    <h4 className="mb-0 ms-3 w-fit-content">01</h4>
                                </div>

                                <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_01.header}</h5>
                                <p className="paragraph-small">
                                    {translation.cards.no_01.paragraph}
                                </p>
                            </div>                            
                        </div>

                    </Col>
                    <Col xs={12} sm={12} lg={4}>
                        <div className="p-0 p-xl-2 p-xxl-3">
                            <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1500 career-recruitment-card-height"  style={{borderTop: "12px solid var(--divelit-lime)", position: "relative", top: "2rem"}}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                    <h4 className="mb-0 ms-3 w-fit-content">02</h4>
                                </div>
                                <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_02.header}</h5>
                                <p className="paragraph-small">
                                    {translation.cards.no_02.paragraph}
                                </p>                            
                            </div>                            
                        </div>
                    </Col>
                    <Col xs={12} sm={12} lg={4}>
                        <div className="p-0 p-xl-2 p-xxl-3">
                            <div className="project-tile-border bg-white project-tile-box-shadow-divelit-grey-20 pt-3 pt-lg-5 pe-3 pe-lg-5 pb-3 pb-lg-5 ps-3 ps-lg-5 scroll-anim-1750 career-recruitment-card-height"  style={{borderTop: "12px solid var(--divelit-grass)", position: "relative", top: "4rem"}}>
                                <div className="d-flex flex-row align-items-center">
                                    <img src={square_black} className="home-experts-img w-fit-content"></img>
                                    <h4 className="mb-0 ms-3 w-fit-content">03</h4>
                                </div>
                                <h5 className="mt-3 md-3 mt-md-5">{translation.cards.no_03.header}</h5>
                                <p className="paragraph-small">
                                    {translation.cards.no_03.paragraph}
                                </p>
                            </div>                            
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
