// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

//components
import BtnBgLemonContact from "../../../../components/btn/btn-bg-lemon-contact";

//files

/**
 * HomeStart section
 * @param props transaltion JSON schema
 * @returns HomeStart section for HOME page (displayed right above FOOTER section)
 */
export default function HomeStart() {
    // This variant is required to enable button to redirect to required language version
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <div className="pt-5 pb-5 home-start-section">
            <div className="mt-3 mt-lg-4 mt-xl-5 mb-3 mb-lg-4 mb-xl-5 pt-3 pt-lg-4 pt-xl-5 pb-3 pb-lg-4 pb-xl-5">
                <Container className="text-center">
                    <Row className="">
                        <Col xs={12} sm={12} className="">
                            <h5 className="mt-0 font-white text-center scroll-anim-750">{translation.header}</h5>
                            <div className="mt-3 mt-md-5 scroll-anim-1000">
                                <BtnBgLemonContact></BtnBgLemonContact>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
}
