// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// components
import OurWork2nInfo from "../../../our-work/components/our-work-2n-info/our-work-2n-info";
import OurWorkLinetSprint from "../../../our-work/components/our-work-linet-sprint/our-work-linet-sprint";

// files

/**
 * EmbeddedProjects section
 * contains selected embedded projects
 * @returns HTML code with EmbeddedProjects section for EMBEDDED page 
 */
export default function EmbeddedProjects() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="mt-5 pt-5 pb-0">
                <Container className="">
                    <Row>
                        <Col xs={12} sm={12} className="text-center scroll-anim-750">
                            <h3 className="font-divelit-grey-50">{translation.header}</h3>
                        </Col>
                    </Row>
                </Container>
                <OurWork2nInfo btn={false}></OurWork2nInfo>
                <OurWorkLinetSprint btn={false}></OurWorkLinetSprint>
            </div>
        </>
    );
}
