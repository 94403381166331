// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import logo_2n from "../../../../files/logos/partners/2n_logo.svg";
import logo_jablotron from "../../../../files/logos/partners/jablotron_logo.svg";
import logo_linet from "../../../../files/logos/partners/linet_logo.svg";
import logo_comap from "../../../../files/logos/partners/comap_logo.svg";

/**
 * EmbeddedPartners section
 * contains logos of our embedded customers
 * @returns HTML code with EmbeddedPartners section for EMBEDDED page
 */
export default function EmbeddedPartners() {
    
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>  
            <div className="bg-divelit-grey-05">
                <Container className="pt-3 pt-lg-5 pb-3 pb-lg-5">
                    <Row className="mt-4 mt-lg-5 mb-4 mb-lg-5">
                        <Col xs={12} sm={12}>
                            <h3 className="text-center font-divelit-grey-50 fade-in-750-down-to-top">
                                {translation.header.part1} {translation.header.part2}
                            </h3>
                            <div className="d-flex flex-column flex-md-row justify-content-center align-items-center home-partners-header-margin">
                                <div className="fade-in-1000-down-to-top">
                                    <img src={logo_2n} className="home-partners-img home-partners-2n-img style-zoom-4" alt={translation.alt.logo_2n}/>
                                </div>
                                <div className="sepparator-vert sepparator-vert-grey-20 fade-in-1000-down-to-top"></div>
                                <div className="fade-in-1250-down-to-top">
                                    <img src={logo_jablotron} className="home-partners-img home-partners-jablotron-img style-zoom-4" alt={translation.alt.jablotron_logo}/>
                                </div>
                                <div className="sepparator-vert sepparator-vert-grey-20 fade-in-1250-down-to-top"></div>
                                <div className="fade-in-1500-down-to-top">
                                    <img src={logo_linet} className="home-partners-img home-partners-linet-img style-zoom-4 p-0" alt={translation.alt.linet_logo}/>
                                </div>
                                <div className="sepparator-vert sepparator-vert-grey-20 fade-in-1500-down-to-top"></div>
                                <div className="fade-in-1750-down-to-top">
                                    <img src={logo_comap} className="home-partners-img home-partners-comap-img style-zoom-4" alt={translation.alt.comap_logo}/>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
