// utils
import React, { useEffect, useState } from "react";
import { useMatch } from "react-router-dom";
import axios from "axios";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../utils/langUtils";
import { globalLoading }  from "../../components/global";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// page sections
import CareerJoinUs from "./components/career-join-us/career-join-us";
import CareerImg from "./components/career-img/career-img";
import CareerHowItWorks from "./components/career-how-it-works/career-how-it-works";
import CareerRecruitment from "./components/career-recruitment/career-recruitment";
import CareerTeam from "./components/career-team/career-team";
import CareerForm from "./components/career-form/career-form";

// files
import CareerWorkPositions from "./components/career-work-positions/career-work-positions";

// storage for data obtained from backend
//let workPositions:any = null;
//let workPositionsList:any = [];
//let requestErrorCode = "";
//let requestErrorText = "";

/**
 * CAREER page
 * @returns HTML code for CAREER page with added work positions (GET from Backend)
 */
export default function Index() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    /*const [isLoading, setIsLoading] = useState(false);

    const [isLoaded, setIsLoaded] = useState(false);
    const [requestError, setRequestError] = useState(false);

    
    // On PAGE load DATA from backend are REQUESTED
    useEffect(() => {
        // Sets GLOBAL LOADING to TRUE
        globalLoading.switch();
        setIsLoading(true);

        workPositionsList = [];
        axios.get("/api/career/getWorkPositionsAll", { params: { lang: language }, timeout: 5000 })
            .then(function (response) {

                workPositions = response.data.data.work_positions;
                
                //console.log(workPositions);
                
                workPositions.map((item:any, i:any) => (
                    workPositionsList.push(item.name)
                    //console.log(item.name)
                ))
                //console.log(workPositionsList);
                // Sets GLOBAL LOADING to FALSE
                globalLoading.switch();
                setIsLoading(false);

                setIsLoaded(true);

                // DEBUG simulates waiting for data - used for debugging
                //window.setTimeout(() => {globalLoading.switch();}, 5000);
            })
            .catch(function (error) {
                // Sets GLOBAL LOADING to FALSE
                globalLoading.switch();
                setIsLoading(false);
                
                // DEBUG whole error message
                //console.log(error);
                
                // error response from backend
                if(error?.code === 'ECONNABORTED') {
                    requestErrorCode = "408";
                    requestErrorText = "Request timeout";
                }
                else {
                    requestErrorCode = "500";
                    requestErrorText = "Internal server error";
                }

                //if(error?.response?.status) {}

                setRequestError(true);
                //console.log(requestError);
            });
    }, [])*/

    // solves broken layout of some pages in build
    //const [hasMounted, setHasMounted] = React.useState(false);
    //React.useEffect(() => {
    //  setHasMounted(true);
    //}, []);
    //if (!hasMounted) {
    //  return null;
    //}
    
    return (
        <>
            <div className="fade-in-750">
                <CareerJoinUs/>
                <CareerImg/>
                <CareerHowItWorks/>
                <CareerRecruitment/>
                <CareerWorkPositions/>
                {/*<CareerForm work_positions={workPositionsList}/>*/}
                <CareerForm/>
                <CareerTeam></CareerTeam>                
            </div>

        </>
    );
}
