// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_divelit_grey_50 from "../../../../files/icons/rency/square_divelit_grey_50.svg";
import square_divelit_grey_70 from "../../../../files/icons/rency/square_divelit_grey_70.svg";
import square_black from "../../../../files/icons/rency/square_black.svg";
import sw_divelit_is_ntb from "../../../../files/images/pages/our-work/divelit-is/divelit_is_v2.webp";

// components
import BtnBorderSoftware from "../../../../components/btn/btn-border-software";

/**
 * OurWorkDivelitIs section
 * contains container with basic onfo about DIVELIT IS project - team size, project lenght and few sentences with image
 * @returns HTML element with OurWorkDivelitIs section for OUR-WORK page
 */
export default function OurWorkDivelitIs(props: {btn: boolean}) {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="bg-white pt-5 pb-5 scroll-anim-1000">
                <Container className="pt-0">
                    <Row className="bg-white project-tile-box-shadow-divelit-grey-10 blog-older-container-ms project-tile project-tile-border mt-5 p-3 p-xl-5 p-lg-4"  style={{borderTop: "12px solid var(--divelit-lemon)"}}>
                        <Col xs={12} sm={12} lg={8} xxl={6} className="order-2 order-lg-1 p-0">
                            <Row>
                                <Col xs={12} sm={12} lg={11} xl={10} xxl={12}>
                                    <Row className="d-flex flex-row align-items-center">
                                        {/*<img src={square_lemon} className="project-square-img w-fit-content mb-2" alt={translation.alt.square_lemon}/>*/}
                                        {/*<Link className="hover-decoration-none w-fit-content" to={getUrlPrefix(language) + "our-work" + "/" + "linet-service-app"}>*/}
                                            <h4 className="font-black w-fit-content mb-0">
                                                {translation.header}
                                            </h4>
                                        {/*</Link>*/}
                                    </Row>
                                    <Row className="">
                                        <Col className="">
                                            <p className="paragraph-small mt-3 mt-xl-4 mb-0">
                                                {translation.paragraph}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={1} xl={2}></Col>
                            </Row>
                            <Row className="mt-3 mt-lg-4 mt-xl-4 mt-xxl-4">
                                <div className="d-flex flex-column">
                                    <div className="flex-xl-grow-1">
                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-row align-items-center">
                                                <img src={square_divelit_grey_50} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                <h5 className="font-divelit-grey-50 align-self-center-md w-fit-content mb-0 ms-3">
                                                    {translation.team.header}
                                                </h5>
                                            </div>
                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                {translation.team.paragraph}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex-xl-grow-1 mt-3">
                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-row align-items-center">
                                                <img src={square_divelit_grey_70} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_grey}/>
                                                <h5 className="font-divelit-grey-70 align-self-center-md w-fit-content mb-0 ms-3">
                                                    {translation.length.header}
                                                </h5>
                                            </div>
                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                {translation.length.paragraph}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="mt-3">
                                        <div className="style-zoom-2 d-flex flex-column flex-md-row">
                                            <div className="d-flex flex-row align-items-center">
                                                <img src={square_black} className="d-inline-block home-experts-img-1 w-fit-content" alt={translation.alt.square_black}/>
                                                <h5 className="font-black align-self-center-md w-fit-content mb-0 ms-3">
                                                    {translation.collaboration.header}
                                                </h5>
                                            </div>
                                            <p className="paragraph-medium w-fit-content mt-2 mt-md-auto mb-auto ms-5 ms-md-3 ps-0 ps-md-0">
                                                {translation.collaboration.paragraph}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Row>
                            <Row className="mt-3 mt-lg-4 ms-1">
                                { props.btn ?
                                <>
                                    <BtnBorderSoftware></BtnBorderSoftware>
                                </>
                                :
                                <></>
                                }
                            </Row>
                        </Col>
                        <Col xs={12} sm={12} lg={4} xxl={6} className="order-1 order-lg-2 text-center">
                            {/*<Link className=""  to={getUrlPrefix(language) + "our-work" + "/" + "linet-service-app"}>*/}
                                <img className="project-divelit-is-tile-img pe-0" alt={translation.alt.divelit_is}  src={sw_divelit_is_ntb}/>
                            {/*</Link>*/}
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
