// utils
import React from "react";
import { useMatch } from "react-router-dom";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// page sections
import OurWorkDivelitIs from "../../../our-work/components/our-work-divelit-is/our-work-divelit-is";
import OurWorkLinetServiceApp from "../../../our-work/components/our-work-linet-service-app/our-work-linet-service-app";
import OurWork2nInfo from "../../../our-work/components/our-work-2n-info/our-work-2n-info";
import OurWorkLinetSprint from "../../../our-work/components/our-work-linet-sprint/our-work-linet-sprint";
import OurWorkNumazon from "../../../our-work/components/our-work-numazon/our-work-numazon";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files

/**
 * HOME OUR WORK section
 * @param props translation JSON schema
 * @returns HTML code with HOME OUR WORK section composed of PROJECTS subsections
 */
export default function HomeOurWork() {
    
    // This variant is required to enable button to redirect to required language version
    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (
        <>
            <div className="bg-white">
                <div className="container pt-3 pb-3 pt-lg-5 pb-lg-5">
                    <div className="row">
                        <div className="col-12 text-center">
                            <h3 className="font-divelit-grey-50 mt-3 mt-lg-5 mb-3 mb-lg-4 scroll-anim-750">{translation.header}</h3>
                        </div>
                    </div>
                    <OurWorkDivelitIs btn={true}></OurWorkDivelitIs>
                    <OurWorkLinetServiceApp btn={true}></OurWorkLinetServiceApp>
                    <OurWork2nInfo btn={true}></OurWork2nInfo>
                    <OurWorkLinetSprint btn={true}></OurWorkLinetSprint>
                    <OurWorkNumazon btn={true}></OurWorkNumazon>
                    {/*<div className="row">
                        <div className="col-12 text-center">
                            <a href={getUrlPrefix(language) + "our-work"} className="button paragraph-small home-hero-top-margin scroll-anim-1000 mt-3 mt-md-5">Show more<img className="button-arrow"></img></a>
                        </div>
                    </div>*/}
                </div>
            </div>
        </>
    );
}
