/**
 * In the case of click on BTN "I WANT TO KNOW MORE" when viewing WORK POSTION
 * value is updated so it will contain name of the WORK POSITION 
 * so it can be preselected on CAREER page in FORM SELECT so users do not have to do it manually 
 */
export let select = {
    value: "",
    switch: function(pos:any) {
        this.value = pos;
    }
}
