// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lemon from "../../../../files/icons/rency/square_lemon.svg";
import square_lime from "../../../../files/icons/rency/square_lime.svg";
import square_grass from "../../../../files/icons/rency/square_grass.svg";
import square_cucumber from "../../../../files/icons/rency/square_cucumber.svg";
import square_black from "../../../../files/icons/rency/square_black.svg";

/**
 * SoftwareHero section
 * contains basin info about our SW development - what we do and which technologies we work with with BG img
 * @returns HTML element for Softwarehero section used on SOFTWARE page
 */
export default function SoftwareHero() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="navbar-size-compensation bg-divelit-grey-90" >
                <div className="software-hero-bg-img pt-3 pt-lg-5">
                    <Container className="pt-3 pt-lg-5">
                        <Row>
                            <Col xs={12} sm={12}>
                                <div className="fade-in-750-down-to-top">
                                    <h2 className="font-white">
                                        {translation.header.part1} <span className="font-divelit-lemon">{translation.header.part2}</span>
                                    </h2>
                                </div>
                                    <Row className="d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 me-5 ms-lg-5 fade-in-1000-down-to-top">
                                        <Col xs={12} sm={12} md={1} className="me-3 me-lg-0">
                                            <img src={square_lemon} className="project-square-img w-fit-content" alt={translation.alt.square_lemon}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">
                                            {translation.list.part1}
                                        </p>
                                    </Row>
                                    <Row className="d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 me-5 ms-lg-5 fade-in-1250-down-to-top">
                                        <Col xs={12} sm={12} md={1} className="me-3 me-lg-0">
                                            <img src={square_lime} className="project-square-img w-fit-content" alt={translation.alt.square_lime}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">
                                            {translation.list.part2}
                                        </p>
                                    </Row>
                                    <Row className="d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 me-5 ms-lg-5 fade-in-1500-down-to-top">
                                        <Col xs={12} sm={12} md={1} className="me-3 me-lg-0">
                                            <img src={square_grass} className="project-square-img w-fit-content" alt={translation.alt.square_grass}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">
                                            {translation.list.part3}
                                        </p>
                                    </Row>
                                    <Row className="d-flex flex-row align-items-center mt-4 mt-md-5 ms-0 me-5 ms-lg-5 fade-in-1750-down-to-top">
                                        <Col xs={12} sm={12} md={1} className="me-3 me-lg-0">
                                            <img src={square_cucumber} className="project-square-img w-fit-content" alt={translation.alt.square_cucumber}/>
                                        </Col>
                                        <p className="paragraph-medium mt-2 mt-lg-0 mb-0 font-white w-fit-content">
                                            {translation.list.part4}
                                        </p>
                                    </Row>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="software-address-section-bg">
                        <Container className="">
                            <Container className="fade-in-1750-down-to-top bg-divelit-lemon project-tile-border mt-3 mt-md-5 p-5 p-md-5">
                                <Row className="">
                                    <Col xs={12} sm={12} className="mx-md-auto pe-0 ps-0">
                                        <div className="row d-flex flex-row align-items-center justify-content-md-center">
                                            <img className="project-square-img w-fit-content" src={square_black}  alt={translation.alt.square_black}/>
                                            <h3 className="font-black w-fit-content mb-0">{translation.technologies.header}</h3>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xs={12} sm={12} md={3} className="mt-4 mt-lg-5 pe-0 ps-0">
                                        <ul className="paragraph-medium w-fit-content mx-md-auto ">
                                            <li>{translation.technologies.list.part1}</li>
                                            <li>{translation.technologies.list.part2}</li>
                                            <li>{translation.technologies.list.part3}</li>
                                            <li>{translation.technologies.list.part4}</li>
                                            <li>{translation.technologies.list.part5}</li>
                                        </ul>  
                                    </Col>
                                    <Col md={1}></Col>
                                    <Col xs={12} sm={12} md={3} className="mt-3 mt-lg-5 pe-0 ps-0">
                                        <ul className="paragraph-medium w-fit-content mx-md-auto">
                                            <li>{translation.technologies.list.part6}</li>
                                            <li>{translation.technologies.list.part7}</li>
                                            <li>{translation.technologies.list.part8}</li>
                                            <li>{translation.technologies.list.part9}</li>
                                            <li>{translation.technologies.list.part10}</li>
                                        </ul>    
                                    </Col>
                                    <Col md={1}></Col>
                                    <Col xs={12} sm={12} md={3} className="mt-3 mt-lg-5 pe-0 ps-0">
                                        <ul className="paragraph-medium w-fit-content mx-md-auto">
                                            <li>{translation.technologies.list.part11}</li>
                                            <li>{translation.technologies.list.part12}</li>
                                            <li>{translation.technologies.list.part13}</li>
                                            <li>{translation.technologies.list.part14}</li>
                                            <li>{translation.technologies.list.part15}</li>
                                        </ul>    
                                    </Col>
                                </Row>                                
                            </Container>
                        </Container>                            
                    </Container>
                </div>
            </div>
        </>

    );
}
