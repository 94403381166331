// utils
import React, { useEffect } from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, getUrlPrefix, LANGUAGES } from "../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

//files
import square_black from "../../files/icons/rency/square_black.svg";

/**
 * ERR 404 PAGE that is returned when user requests URL that does not exist 
 * @returns ERR 404 PAGE
 */
export default function Err404() {


    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    /**
     * sets ARTICLE container height so without content it has at least the height required to force FOOTER to be on the bottom of the screen viewport not right bellow latest article with grey backgound covering the rest of the PAGE
     */
    function articleContainerHeight() {
        // inner window height - our reference point
        let windowHeight = window.innerHeight;
        let page404Container = document.getElementById("page404Container");

        // page header height - this needs to be substracted from WINDOW height
        //let tagHeader = document.getElementById("tagHeader");
        //let tagHeaderHeight = tagHeader?.offsetHeight;
        
        // footer height - this also needs to be substracted from WINDOW height
        let footer = document.getElementById("footer");
        let footerHeight = footer?.offsetHeight;

        // total height to be substracted form WINDOW height
        let totalHeight = 0
        if(footerHeight) {
            totalHeight = footerHeight;
        };

        // our final
        if(page404Container !== null) {
            page404Container.style.height = String(windowHeight - totalHeight) + "px";
        }     
    };

    useEffect(() => {
        articleContainerHeight();
    })
    window.addEventListener("resize", (event) => {
        articleContainerHeight();
    });

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (
        <>
            <div className="fade-in-750">
                <div className="navbar-size-compensation page-404-height bg-white" id="page404Container">
                    <Container className="h-100">
                        <div className="d-flex flex-column text-center justify-content-center h-100">
                            <div className="d-flex flex-row align-items-center mx-auto fade-in-750-down-to-top">
                                <img src={square_black} className="home-experts-img" alt={translation.alt.square_black}/>
                                <h2 className="mb-0 ms-1 ms-sm-3 ms-md-4">{translation.err_404_header} {translation.err_404_number}</h2>
                            </div>                          
                            <h3 className="mt-3 mt-lg-5 fade-in-1000-down-to-top">{translation.err_404}</h3>
                            <Row className="fade-in-1250-down-to-top">
                                <Col xs={12} sm={12} lg={6} className="mx-auto">
                                <p className="paragraph-small">{translation.err_404_description}</p>                                    
                                </Col>
                            </Row>
                            <a href={getUrlPrefix(language)} className="mx-auto mt-3 button-wo-border bg-divelit-lemon paragraph-small fade-in-1500-down-to-top">{translation.err_404_btn}<img className="button-arrow"></img></a>                                     
                        </div>
                    </Container>
                </div>
            </div>
        </>
    );
}
