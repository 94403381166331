import React, { StrictMode } from 'react';
import * as ReactDOMClient from 'react-dom/client';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";
import { createRoot, hydrateRoot } from "react-dom/client"
import { Container } from 'react-bootstrap';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// DEPRECATED
const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
} else {
    render(<App />, rootElement);
}


// WORKS without cookies
//const rootElement = document.getElementById("root");
//
//if (rootElement.hasChildNodes()) {
//    hydrateRoot(
//        rootElement,
//        <React.StrictMode>
//            <App />
//        </React.StrictMode>,
//    );
//} else {
//    createRoot(document.getElementById("root")).render(
//        <React.StrictMode>
//            <App />
//        </React.StrictMode>,
//    );
//}

//const rootElement = document.getElementById("root");
//const root = createRoot(rootElement);

//const root = hydrateRoot(rootElement, <App />);
//if (rootElement.hasChildNodes()) {
//    //root.hydrate(<App />);
//    root.hydrateRoot(rootElement, <App />);
//
//} else {
//    //hydrate(root);
//    root.render(<App />);
//   // hydrateRoot(rootElement, <App />);
//    //createRoot(rootElement);
//    //createRoot(rootElement, <App />);
//}

//const root = ReactDOMClient.hydrateRoot(rootElement, <App />);
//if (rootElement.hasChildNodes()) {
//    //root.hydrate(<App />);
//    ReactDOMClient.hydrateRoot(rootElement, <App />);
//
//} else {
//    //hydrate(root);
//    ReactDOMClient.createRoot(rootElement);
//   // hydrateRoot(rootElement, <App />);
//    //createRoot(rootElement);
//    //createRoot(rootElement, <App />);
//}

//const rootElement = document.getElementById("root");
//const root = createRoot(rootElement);
////hydrateRoot(rootElement, <App />);
//if (rootElement.hasChildNodes()) {
//    root.hydrate(rootElement, <App />);
//} else {
//    root.render(<App />);
//}

//const rootElement = document.getElementById("root");
//const root = createRoot(rootElement);
//
//root.render(
//    <StrictMode>
//        <App />
//    </StrictMode>
//);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
