// utils
import React from "react";
import { Link, useMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCake } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Container, Row, Col } from "react-bootstrap";


// custom utils
import  { getCurrentLanguage, getUrlPrefix, LANGUAGES } from "../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import img_divelit_d_vectorark_logo from "../../src/files/logos/divelit/vedle-sebe/divelit-vedle-sebe-šedé-rgb.svg";
import consortium_avdzp_v2 from "../../src/files/logos/consortiums/logo-avdzp/CZ/AVDZP-logo_color_RGB_72dpi_v2.png";
import consortium_amsp_v3 from "../../src/files/logos/consortiums/amsp/amsp300x85logo_v3.png";
import consortium_rhk_brno from "../../src/files/logos/consortiums/rhk-brno/Logo_RHKBRNO_2.png";
import consortium_ohk_olomouc_v6 from "../../src/files/logos/consortiums/ohk-olomouc/hkol_v6.png";
import consortium_hl7 from "../../src/files/logos/consortiums/hl-7/hl7_v2.png";

import consortium_eia_v2 from "../../src/files/logos/consortiums/eia/logo-obrys_v2.png";

/**
 * FOOTER component with small navbar and links to social media and email with consortiums logo that Divelit is part of
 * @returns FOOTER component
 */
export default function Footer() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    
    // DEBUG
    //console.log("FOOTER LANG: ", language);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // Base64 encoded mail address UTF-8, LF (Unix)
    var divelitInfoMail = "aW5mb0BkaXZlbGl0LmN6";
    const divelitInfo = document.getElementById("divelit-info");
    if(divelitInfo) {
        divelitInfo.innerHTML = atob(divelitInfoMail);
        divelitInfo.setAttribute("href", "mailto:".concat(atob(divelitInfoMail)));
        divelitInfo.setAttribute("data-hover", atob(divelitInfoMail));
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (
        <div className="bg-divelit-grey-90" id="footer">

            <hr className="sepparator-horiz-divelit-grey-30"></hr>

            <Container>
                <Row>
                    <Col xs={12} sm={12} className="d-flex flex-column flex-lg-row footer-navbar">
                        <div className="align-self-lg-center mx-auto mx-lg-0">
                            <img className="footer-logo font-divelit-grey-50" src={img_divelit_d_vectorark_logo}  alt={translation.divelit_logo_alt}></img>
                        </div>
                        <div className="align-self-lg-center mt-3 mt-lg-0 mx-auto mx-lg-0 ms-lg-3">
                            <Link className="footer-link paragraph-small font-divelit-grey-50" to={getUrlPrefix(language)} data-hover={translation.about_us}>{translation.about_us}</Link>
                        </div>
                        <div className="align-self-lg-center mt-3 mt-lg-0 mx-auto mx-lg-0 ms-lg-3">
                            <Link className="footer-link paragraph-small font-divelit-grey-50" to={getUrlPrefix(language) + "contact"} data-hover={translation.contact}>{translation.contact}</Link>
                        </div>
                        <div className="align-self-lg-center mt-3 mt-lg-0 mx-auto mx-lg-0 ms-lg-3">
                            <Link className="footer-link paragraph-small font-divelit-grey-50" to={getUrlPrefix(language) + "career"} data-hover={translation.career}>{translation.career}</Link>
                        </div>
                        <div className="align-self-lg-center flex-grow-1 mt-3 mt-lg-0 mx-auto mx-lg-0 ms-lg-3">
                            {/*<Link className="footer-link paragraph-small font-divelit-grey-50" to={getUrlPrefix(language) + "blog"} data-hover={translation.blog}>{translation.blog}</Link>*/}
                        </div>

                        <div className="align-self-lg-center mx-auto mt-5 mt-lg-0">
                            <a id="divelit-info" className="footer-link-mail paragraph-small font-divelit-grey-50" href="" data-hover=""></a>
                        </div>

                        <div className="d-flex flex-row mx-auto mt-3 mt-lg-0 ms-lg-3">
                            <a className="align-self-center" href="https://www.linkedin.com/company/divelit-system-s.r.o.?challengeId=AQHEGhtr6wkfswAAAXyp6IKjF5GJN5MRSdj63GgHQmCwO7Bjq_jB2pY-A57Orh0E5BKVMw9bng5s7bxFHk5Hfy3tsGst96ReYg&submissionId=30599107-9877-b016-b673-06d75cc6e81c"  target="_blank"  rel="noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} className="footer-link-icon font-divelit-grey-50 fa fa-linkedin-square"/>
                            </a>
                            {/*<a className="align-self-center ms-3" href="#">
                                <FontAwesomeIcon icon={faSquareFacebook} className="footer-link-icon font-divelit-grey-50 fa fa-facebook-square"/>
                            </a>*/}
                        </div>
                    </Col>
                </Row>

            </Container>

            <hr className="sepparator-horiz-divelit-grey-30"></hr>

            <Container>
                <Row>
                    {/*<Col xs={12} sm={12} className="text-center">
                        <h5 className="font-divelit-grey-50 mt-5" style={{}}>Jsme hrdými členy</h5>
                    </Col>*/}
                </Row>
                <Row>
                    <Col xs={12} sm={12} className="mt-4 mt-lg-5 mb-4 mb-lg-5">
                        <div className="d-flex flex-column flex-lg-row  mx-auto w-fit-content flex-wrap justify-content-center">
                            <div className="align-self-center align-self-lg-center mt-4 mt-lg-0 me-0 me-lg-4 ms-0 ms-lg-3">
                                <Link className="footer-link" to={"https://www.avdzp.cz/"} data-hover={"https://www.avdzp.cz/"} target="_blank"  rel="noreferrer"><img className="footer-logo footer-consortium-avdzp" src={consortium_avdzp_v2} alt={translation.avdzp_logo_alt}></img></Link>
                            </div>
                            <div className="align-self-center align-self-lg-center mt-4 mt-lg-0 me-0 me-lg-4 ms-0 ms-lg-3">
                                <Link className="footer-link" to={"https://rhkbrno.cz/"} data-hover={"https://rhkbrno.cz/"} target="_blank"  rel="noreferrer"><img className="footer-logo footer-consortium-rhkbrno" src={consortium_rhk_brno} alt={translation.rhk_brno_logo_alt}></img></Link>
                            </div>
                            <div className="align-self-center align-self-lg-center mt-4 mt-lg-0 me-0 me-lg-4 ms-0 ms-lg-3">
                                <Link className="footer-link" to={"https://www.hkol.cz/"} data-hover={"https://www.hkol.cz/"} target="_blank"  rel="noreferrer"><img className="footer-logo footer-consortium-ohkol" src={consortium_ohk_olomouc_v6} alt={translation.ohk_olomouc_logo_alt}></img></Link>
                            </div>
                            <div className="align-self-center align-self-lg-center mt-4 mt-lg-0 me-0 me-lg-4 ms-0 ms-lg-3">
                                <Link className="footer-link" to={"https://www.electroindustry.cz/"} data-hover={"https://www.electroindustry.cz/"} target="_blank"  rel="noreferrer"><img className="footer-logo footer-consortium-eia" src={consortium_eia_v2} alt={translation.eia_logo_alt}></img></Link>
                            </div>
                            <div className="align-self-center align-self-lg-center mt-4 mt-lg-4 mt-xxl-0 me-0 me-lg-4 ms-0 ms-lg-3">
                                <Link className="footer-link" to={"https://amsp.cz/"} data-hover={"https://amsp.cz/"} target="_blank"  rel="noreferrer"><img className="footer-logo footer-consortium-amsp" src={consortium_amsp_v3} alt={translation.amsp_logo_alt}></img></Link>
                            </div>
                            <div className="align-self-center align-self-lg-center mt-4 mt-lg-4 mt-xxl-0 me-0 me-lg-4 ms-0 ms-lg-3">
                                <Link className="footer-link" to={"https://www.hl7cr.eu/"} data-hover={"https://www.hl7cr.eu/"} target="_blank"  rel="noreferrer"><img className="footer-logo footer-consortium-hl7" src={consortium_hl7} alt={translation.hl7_logo_alt}></img></Link>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>

            <hr className="sepparator-horiz-divelit-grey-30 mb-3"></hr>
            
            <Container>
                <Row>
                    <Col xs={12} sm={12} className="d-flex flex-column flex-lg-row mb-3">
                        <div className="align-self-center flex-grow-1 mt-auto mb-auto">
                            <p className="paragraph-extra-small font-divelit-grey-50 mb-0">&copy; DIVELIT system s.r.o. {new Date().getFullYear()}</p>
                        </div>
                        <div className="align-self-center mt-auto mb-auto">
                            <a data-cc="show-preferencesModal" className="footer-copyright paragraph-extra-small font-divelit-grey-50">{translation.cookies_policy}</a>
                        </div>
                    </Col>
                </Row>
            </Container>
                

        </div>
    );
}
