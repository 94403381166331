// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_black from  "../../files/icons/rency/square_black.svg";
import { alignPropType } from "react-bootstrap/esm/types";


/**
 * SectionLoadingError section
 * Being displayed anywhere on WEB where external content is being laoded if an error occurs during that process. 
 * @returns HTML code for SectionLoadingError component
 */
export default function SectionLoadingError(props: {requestErrorCode:string, requestErrorText:string, reloadAddress:string}) {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";

    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>
            <div className="navbar-size-compensation page-404-height bg-white scroll-anim-750">
                <Container className="h-100 blog-older-container bg-white mt-5 mb-5 p-3 p-lg-5" style={{borderTop: "12px solid var(--linet-red)"}}>
                    <div className="d-flex flex-column text-center justify-content-center h-100">
                        <div className="d-flex flex-row align-items-center mx-auto">
                            <img src={square_black} className="home-experts-img" alt={translation.alt.black_colored_square}/>
                            <h3 className="mb-0 ms-1 ms-sm-3 ms-md-4">{translation.error_header} {props.requestErrorCode}</h3>
                        </div>                          
                        <h5 className="mt-4 mt-lg-5">{props.requestErrorText}</h5>
                        <Row className="">
                            <Col xs={12} sm={12} lg={5} className="mx-auto">
                            <p className="paragraph-small">{translation.error_description}</p>                                    
                            </Col>
                        </Row>
                        <a href={props.reloadAddress} className="mx-auto mt-3 button-w-border paragraph-small">{translation.error_btn}<img className="button-arrow"></img></a>                                     
                    </div>
                </Container>
            </div>
        </>
    );
}
