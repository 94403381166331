// utils
import React, { useEffect, useState } from "react";
import { Link, useLocation, useMatch } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

// custom utils
import { getCurrentLanguage, getNewUrlChangeLang, getUrlPrefix, LANGUAGES } from "../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import img_divelit_logo from "../../src/files/logos/divelit/vedle-sebe/divelit-vedle-sebe-barevné-na-tmavou-rgb.svg";

/**
 * NAVBAR component
 * @returns NAVBAR component
 */
export default function Navbar() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";


    let translation = translation_cs;
    let language = getCurrentLanguage(lang);
    // DEBUG
    //console.log("NAVBAR ", language);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }

    /**
     * When user changes language this function performs refresh of the whole page in selected language and as a result not only page content
     * but also Cookies dialog is shown in selected language (without page relaod dialog was left in previous language mutation)
     * @param language requested language
     */
    function reloadFunction(language: LANGUAGES) {
        window.location.reload(); 
        
        let url = getUrl(language);
        
        
        // window.location.search is needed for storing information about OPENED BLOG ARTICLE so it won't be lost information with language change
        // new language mutation of the ARTICLE will be loaded
        window.location.href = url + window.location.search;

    }

    /**
     * CODE BELLOW is being triggered by reload or page change (so if PAGE reload is performed there it would cause nonstop cyclic page reloading - AVOID!)
     * When user switches language this function will update current URL with selected language so user will be redirected to a page with required language
     * @param val required language
     * @returns new URL that contains selected language
     */
    function getUrl(val: LANGUAGES) {
        // clicked same language as is already used -> do nothing
        if(val === language)
        {
            console.log(window.location.pathname);
            console.log(window.location.href);
            console.log(window.location.search);

            return window.location.pathname;
        }
        return getNewUrlChangeLang(language, val, window.location.pathname);
    }

    const [navbarUp, setNavbarUp] = useState(true);
    const [navbarTransparent, setNavbarTransparent] = useState(false);

    const { pathname } = useLocation();

    /**
     * "on load"
     */
    useEffect(() => {
        // NAVBAR HIDING according to scrolling - NAVBAR is defaultly visible
        let last_scroll_top = 0;
        setNavbarUp(true);

        // if page contains jumbotron... make navbar transparent is it good wa to do it? probably not.. does it work? yes
        const element_jumbotron = document.querySelector('.jumbotron-cover');
        if (element_jumbotron)
        {   
            setNavbarTransparent(true)
        }
        else
        {
            setNavbarTransparent(false)
        }

        const onScroll = () => {
            let scroll_top = window.scrollY;
            // DEBUG 
            //console.log("WINDOW:", useWindowDimensions(), "px");

            // HIDE this part is responsible for navbar hiding
            if(scroll_top > 30) {
                if(scroll_top < last_scroll_top) {
                    //SHOW NAVBAR
                    setNavbarUp(true)
                }
                else {
                    // HIDE NAVBAR
                    setNavbarUp(false)
                }
                last_scroll_top = scroll_top;
            }

            // jumbotron element on the PAGE so NAVBAR will be transparent on the top
            if (element_jumbotron)
            {
                if(scroll_top < 30) {
                    setNavbarTransparent(true)
                }
                else {
                    setNavbarTransparent(false)
                }
            }
        };

        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, [pathname]); // [pathname] - so the navbar is updated on url change (new page can have transparent navbar or hidden)

    /**
     * "on load"
     * removes "show" class on page load from navbar collapsible - (if visible it has "show" class)
     * 
     * 1) this resolves BUG on mobiles when user clicked page in opened navbar and on new page navbar was shown opened instead of closed and it was hiding page content
     * 
     * 2) somehow it seems to also SOLVE BUG when user on mobile clicked on different page in FOTTER NAVBAR and new page was loaded with whole navbar hidden 
     * and user had to scroll down and up to make it visible again
     * 
     */
    useEffect(() => {
       const navbarContent = document.getElementById("myNavbar");
       navbarContent?.classList.remove("show");

    }, [pathname]); // [pathname] - so the navbar is updated on url change (new page can have transparent navbar or hidden)

    /**
     * "on load"
     * 
     * checks actual URL and according to its value it changes navbar links color - active page will have colored link in the navbar
     * 
     * other links will be white
     */
    useEffect(() => {
        let url = window.location.pathname
        //console.log(String(url));
        const nav_items = document.querySelectorAll(".nav-link")
        
        const dropdown_items = document.querySelectorAll(".dropdown-link")
        //console.log(nav_items[0]);

        // going through all nav-link elements
        for(let i = 0; i < nav_items.length; i++){
            //console.log(nav_items[i].getAttribute("href"))

            // link will be ACTIVE if its href is same as actual URL
            if(nav_items[i].getAttribute("href") === String(url)){
            
                nav_items[i].classList.add('navbar-active');
            }
            // otherwise it will be white
            else {
                nav_items[i].classList.remove('navbar-active');
            }
        }

        for( let i = 0; i < dropdown_items.length; i++) {
            let element = document.querySelector(".services-id");
            
            if(String(url) === '/software' || String(url) === '/embedded' || String(url) === '/en/software' || String(url) === '/en/embedded'){
                element?.classList.add("navbar-active");
                //if(dropdown_items[i].getAttribute("href") === String(url)) {
                //    console.log("++++++",dropdown_items[i].getAttribute("href"), String(url));
                //    dropdown_items[i].classList.add("navbar-active");
                //}
            }
            else {
                element?.classList.remove("navbar-active");
            }
        }
    })

    
    //function tmpFunction () {
    //    reloadFunction(LANGUAGES.Czech);
    //    document.documentElement.lang = "cs";
    //}

    //function tmpEnFunction () {
    //    reloadFunction(LANGUAGES.English);
    //    document.documentElement.lang = "en";
    //}

    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }
    
    return (

        <nav id="navbar" className={"navbar autohide navbar-expand-lg fixed-top navbar-dark py-0 navbar-scrolled-up mt-auto mb-auto "
        /**
         * HIDE next line is responsible for navbar hiding (removing of the class "navbar-scrolled-up" is required)
         * without it ill be displayed hidden navbar on MOBILE when user uses FOOTER navbar
         */
        + (navbarUp ? " " : (document.getElementById("navbar")?.classList.remove("navbar-scrolled-up") + " navbar-scrolled-down"))
        + (navbarTransparent ? " transparent" : " ")} >
        
            <Link to={getUrlPrefix(language)} data-hover="DIVELIT">
                <img className="navbar-brand" src={img_divelit_logo} alt={translation.divelit_logo_alt}></img>
            </Link>

            <div style={{paddingRight: "1em"}}>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#myNavbar"
                        aria-controls="myNavbar" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon white"/>
                </button>
            </div>
            <div className="collapse navbar-collapse" id="myNavbar">
                <ul className="navbar-nav ms-auto align-items-center">
                    {/*<hr className="navbar-mobile-separator"></hr>*/}
                    <li className="nav-item">
                        <Link className="nav-link paragraph-medium font-white" to={getUrlPrefix(language)} data-hover={translation.about_us}>{translation.about_us}</Link>
                    </li> 
                    {/*<hr className="navbar-mobile-separator"></hr>*/}
                    {/*<li className="nav-item dropdown">
                        <a className="nav-link paragraph-medium font-white dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            SLUŽBY
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                            <Link className="dropdown-item paragraph-medium font-white pt-3 pe-3 pb-3 ps-3" style={{width: "15rem", height: "4rem", textAlign: "left"}} to={getUrlPrefix(language) + "embedded"}>EMBEDDED VÝVOJ</Link>
                            <Link className="dropdown-item paragraph-medium font-white pt-3 pe-3 pb-3 ps-3" style={{width: "15rem", height: "4rem", textAlign: "left"}} to={getUrlPrefix(language) + "software"}>SOFTWAROVÝ VÝVOJ</Link>
                            <Link className="dropdown-item paragraph-medium font-white pt-3 pe-3 pb-3 ps-3" style={{width: "15rem", height: "4rem", textAlign: "left"}} to={getUrlPrefix(language) + "uwb"}>UWB VÝVOJ</Link>
                        </ul>
                    </li>*/}
                    <div className="dropdown show nav-dropdown-item">
                        <button className="services-button services-button-margin paragraph-medium hover-divelit-lemon text-center services-id" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style={{border: "none", paddingBottom: "0", marginBottom: "-1px"}}>
                                {translation.services.header}<FontAwesomeIcon icon={faCaretDown} className="fa-solid fa-caret-down ms-3"/>
                        </button>
                        <div className="dropdown-menu services-dropdown-margin" aria-labelledby="dropdownMenuLink">
                            <Link to={getUrlPrefix(language) + "embedded"} style={{textDecoration: "none"}} className="dropdown-link">
                                <button className={"ps-3 pe-3 dropdown-item dropdown-item-services paragraph-medium font-white w-100"}>
                                    {translation.services.list.embedded}
                                </button>
                            </Link>
                            <Link to={getUrlPrefix(language) + "software"} style={{textDecoration: "none"}} className="dropdown-link">
                                <button className={"ps-3 pe-3 dropdown-item dropdown-item-services paragraph-medium font-white w-100"}>
                                    {translation.services.list.software}
                                </button>
                            </Link>
                            {/*<Link to={getUrlPrefix(language) + "uwb"} style={{textDecoration: "none"}}>
                                <button className={"ps-3 pe-3 dropdown-item dropdown-item-services paragraph-medium font-white w-100"}>
                                    {translation.services.list.uwb}
                                </button>
                            </Link>*/}
                            {/*<button onClick={() => reloadFunction(LANGUAGES.German)} className={"dropdown-item paragraph-small font-white" + " " + (language === LANGUAGES.German ? "lang-button-active" : "")} >
                                DE
                            </button>*/}
                        </div>
                    </div>
                    {/*<li className="nav-item">
                        <Link className="nav-link paragraph-medium font-white" to={getUrlPrefix(language) + "our-work"} data-hover={translation.our_work}>{translation.our_work}</Link>
                    </li>*/}
                    <li className="nav-item">
                        <Link className="nav-link paragraph-medium font-white" to={getUrlPrefix(language) + "contact"} data-hover={translation.contact}>{translation.contact}</Link>
                    </li>
                    {/*<hr className="navbar-mobile-separator"></hr>*/}
                    <li className="nav-item">
                        <Link className="nav-link paragraph-medium font-white" to={getUrlPrefix(language) + "career"} data-hover={translation.career}>{translation.career}</Link>
                    </li>
                    {/*<hr className="navbar-mobile-separator"></hr>*/}
                    {/*<li className="nav-item">
                        <Link className="nav-link paragraph-medium font-white" to={getUrlPrefix(language) + "blog"} data-hover={translation.blog}>{translation.blog}</Link>
                </li>*/}
                    {/*<hr className="navbar-mobile-separator"></hr>*/}
                </ul>
                <div className="dropdown show" >
                    <button className="lang-button lang-button-margin paragraph-medium" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {language.toUpperCase()}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <button onClick={() => reloadFunction(LANGUAGES.Czech)} className={"dropdown-item paragraph-medium font-white" + " " + (language === LANGUAGES.Czech ? "lang-button-active" : "")} >
                            CS
                        </button>
                        <button onClick={() => reloadFunction(LANGUAGES.English)} className={"dropdown-item paragraph-medium font-white" + " " + (language === LANGUAGES.English ? "lang-button-active" : "")} >
                            EN
                        </button>
                        {/*<p className="font-white">{language}</p>
                        
                        <button onClick={() => tmpFunction()}>
                            <div > CS </div>
                        </button>
                        <button onClick={() => tmpEnFunction()}>
                            <div > EN </div>
                        </button>*/}
                        <button style={{display: "none"}}>
                            <Link to={getUrl(LANGUAGES.Czech)}> CS </Link>
                        </button>
                        <button style={{display: "none"}}>
                            <Link to={getUrl(LANGUAGES.English)}> EN </Link>
                        </button>
                        <Link style={{display: "none"}} to={getUrlPrefix(language) + 'err404'}></Link>
                        {/*<button onClick={() => reloadFunction(LANGUAGES.German)} className={"dropdown-item paragraph-small font-white" + " " + (language === LANGUAGES.German ? "lang-button-active" : "")} >
                            DE
                        </button>*/}
                  </div>
                </div>
            </div>
        </nav>
        
    );
}
