// utils
import React from "react";
//import { useMatch } from "react-router-dom";

// custom utils
//import { getCurrentLanguage, LANGUAGES } from "../../utils/langUtils";

// translations
//import translation_cs from "../contact/lang/cs.json";
//import translation_en from "../contact/lang/en.json";
//import translation_de from "../contact/lang/de.json";

//page sections
import ContactForm from "./components/contact-form/contact-form";
import ContactAddress from "./components/contact-address/contact-address";
import ContactTeam from "./components/contact-team/contact-team";

// files



/**
 * CONTACT page
 * Handles whole CONTACT page with form - language, required input check, POST to the server as multipart/form-data
 * @returns HTML element for CONTACT page with <form>
 */
export default function Index() {

    /*const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }*/

    // solves broken layout of some pages in build
    //const [hasMounted, setHasMounted] = React.useState(false);
    //React.useEffect(() => {
    //  setHasMounted(true);
    //}, []);
    //if (!hasMounted) {
    //  return null;
    //}
    
    return (
        <>
            <div className="fade-in-750">
                <ContactForm></ContactForm>
                <ContactAddress></ContactAddress>
                <ContactTeam></ContactTeam>                
            </div>

        </>

    );
}
