// utils
import React from "react";
import { useMatch } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

// custom utils
import { getCurrentLanguage, LANGUAGES } from "../../../../utils/langUtils";

// translations
import translation_cs from "./lang/cs.json";
import translation_en from "./lang/en.json";
import translation_de from "./lang/de.json";

// files
import square_lemon from "../../../../files/icons/rency/square_lemon.svg";
import square_lime from "../../../../files/icons/rency/square_lime.svg";
import square_grass from "../../../../files/icons/rency/square_grass.svg";

// types
type schemaJson = typeof translation_cs;

/**
 * CareerhowItWorks section 
 * list displayed in square background with box shadow
 * @returns HTML code of CareerHowItWorks section for CAREER page
 */
export default function CareerHowItWorks() {

    const match = useMatch("/:lang/*");
    let lang = (match && match.params.lang) ? match.params.lang : "";
    let translation = translation_cs;

    let language = getCurrentLanguage(lang);
    switch (language) {
        case LANGUAGES.Czech:
            translation = translation_cs;
            break;
        case LANGUAGES.English:
            translation = translation_en;
            break;
        case LANGUAGES.German:
            translation = translation_de;
            break;
    }
    
    // solves broken layout of some pages in build
    const [hasMounted, setHasMounted] = React.useState(false);
    React.useEffect(() => {
      setHasMounted(true);
    }, []);
    if (!hasMounted) {
      return null;
    }

    return (
        <>        
            <div className="pt-3 pt-lg-5 pb-3 pb-lg-5 career-how-it-works-section">
                <Container className="">
                    <Container className="mt-3 mt-lg-5 mb-3 mb-lg-5 contact-address-container-padding project-tile-border project-tile-box-shadow-divelit-grey-10 bg-white scroll-anim-1250">
                        <Row className="">
                            <Col xs={12} sm={12} md={4} className="">
                                <div className="scroll-anim-1500">
                                    <img src={square_lemon} className="home-experts-img mt-3 mt-md-0 mb-3 mb-md-4" alt={translation.alt.square_lemon}/>       
                                    <h4 className="font-divelit-lemon">01</h4>
                                    <h5 className="md-3 mt-md-5">{translation.part1.header}</h5>
                                    <p className="paragraph-small">{translation.part1.paragraph}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={4} className="">
                                <div className="scroll-anim-1750">
                                    <img src={square_lime} className="home-experts-img mt-5 mt-md-0 mb-3 mb-md-4" alt={translation.alt.square_lime}/> 
                                    <h4 className="font-divelit-lime">02</h4>
                                    <h5 className="md-3 mt-md-5">{translation.part2.header}</h5>
                                    <p className="paragraph-small">{translation.part2.paragraph}</p>
                                </div>
                            </Col>
                            <Col xs={12} sm={12} md={4} className="">
                                <div className="scroll-anim-2000">
                                    <img src={square_grass} className="home-experts-img mt-5 mt-md-0 mb-3 mb-md-4" alt={translation.alt.square_grass}/>                                         
                                    <h4 className="font-divelit-grass">03</h4>
                                    <h5 className="md-3 mt-md-5">{translation.part3.header}</h5>
                                    <p className="paragraph-small">{translation.part3.paragraph}</p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </div>
        </>
    );
}
